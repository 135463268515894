import {
  Box,
  Button,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
  Grid,
  Paper,
  Container,
} from "@mui/material";
import {
  configurationViewModel,
  printAuditViewModel,
  productViewModel,
  printLogViewModel,
} from "../api";
import { ChangeEventHandler, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import CheckIcon from "@mui/icons-material/Check";
import BackIcon from "@mui/icons-material/ArrowBack";
import HistoryIcon from "@mui/icons-material/History";
import { modalStyle, modalPrintLogStyle } from "../styles/styles";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import React from "react";
import { GetConfig, Getusername, PrintAuditLog } from "../utils/ApiUtils";
import moment from "moment";
import { currencyFormat } from "../utils/formatting";
import BrotherSdk from "bpac-js";
import { IsAdmin } from "../utils/MsGraphApiCall";
type printProps = {
  product: productViewModel;
  batchNumber: string;

  bestBeforeDate: string;

  onbestBeforeDateChangedValue: Function;
  onNumberofLabelsChangedValue: Function;
  onBatchNumberChangedValue: Function;
  NumberOfLabels: number;

  printLogs: printLogViewModel[];
};

const PrintDialog = (props: printProps) => {
  const [isAdmin, setIsAdmin] = React.useState<Boolean>(false);
  const [openWaringModal, setWarningModalOpenState] = useState<boolean>(false);
  const [labeltemplate, setlabeltemplate] = React.useState<string>("");
  const [OpenPrintLog, setOpenPrintLog] = React.useState<boolean>(false);
  const [selectMode, setSelectMode] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] =
    React.useState<printLogViewModel | null>(null);
  const [labelconfiguration, setConfiguration] =
    React.useState<configurationViewModel | null>(null);
  React.useEffect(() => {
    IsAdmin().then((response: boolean) => {
      setIsAdmin(response);
    });
  }, [setIsAdmin]);
  const printLabels = React.useCallback(
    async (
      passedvalue: productViewModel,
      copies: number,
      batchnumber: string,
      bestbefore: string,
      passedlabelconfiguration: configurationViewModel,
      passedServingsPerPackage: number
    ) => {
      try {
        if (passedvalue.isFood && (batchnumber === "" || bestbefore === "")) {
          setWarningModalOpenState(true);
          return;
        }

        let LabelTemplate = "";

        if (passedvalue.isFood) {
          console.log(passedvalue.useCustomTemplate);
          if (passedvalue.useCustomTemplate) {
            console.log(" custom Food");
            LabelTemplate =
              process.env.REACT_APP_LOCALFOLDER +
              passedvalue.customTemplateFileName;
          } else {
            console.log("Food");
            LabelTemplate =
              process.env.REACT_APP_LOCALFOLDER +
              passedlabelconfiguration.foodDefaultLabelName;
          }
        } else {
          console.log("not food");
          LabelTemplate =
            process.env.REACT_APP_LOCALFOLDER +
            passedlabelconfiguration.nonFoodDefaultLabelName;
        }

        const nonFoodData = {
          ProductName: passedvalue.productName,
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
        };
        const fooddata = {
          ServingSize:
            passedvalue.servingSize.toString() + passedvalue?.servingUnit,
          ServingsPerPackage: passedServingsPerPackage.toFixed(2).toString(),
          Ingredients: passedvalue.ingredients.join(","),
          ProductName: passedvalue.productName,
          Warning: passedvalue.mayContain.join(", "),
          CareInstructions: passedvalue.careInstructions.join(","),
          Description: passedvalue.productDescription,
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
          BestBefore: bestbefore,
          BatchNumber: batchnumber,
          EnergyPerServing: passedvalue.energyPerServing.toFixed(1).toString(),
          FatPerServing: passedvalue.totalFatPerServing.toFixed(1).toString(),
          CarbsPerServing: passedvalue.carbohydratesPerServing
            .toFixed(1)
            .toString(),
          SugarsPerServing: passedvalue.sugarsPerServing.toFixed(1).toString(),
          ProteinPerServing: passedvalue.proteinPerServing
            .toFixed(1)
            .toString(),
          EnergyPer100: passedvalue.energyPer100.toFixed(1).toString(),
          FatPer100: passedvalue.totalFatPer100.toFixed(1).toString(),
          CarbsPer100: passedvalue.carbohydratesPer100.toFixed(1).toString(),
          SugarsPer100: passedvalue.sugarsPer100.toFixed(1).toString(),
          ProteinPer100: passedvalue.proteinPer100.toFixed(1).toString(),
          SodiumPerServing: passedvalue.sodiumPerServing.toFixed(1).toString(),
          SodiumPer100: passedvalue.sodiumPer100.toFixed(1).toString(),
        };
        const customfoodData = {
          ServingSize:
            passedvalue.servingSize.toString() + passedvalue?.servingUnit,
          ServingsPerPackage: passedServingsPerPackage.toFixed(2).toString(),
          ProductName: passedvalue.productName,
          Warning: passedvalue.mayContain.join(", "),
          Contains: "",
          CareInstructions: passedvalue.careInstructions.join(","),
          Description: passedvalue.productDescription ?? "",
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
          BestBefore: bestbefore,
          BatchNumber: batchnumber,
          EnergyPerServing: passedvalue.energyPerServing.toFixed(1).toString(),
          FatPerServing: passedvalue.totalFatPerServing.toFixed(1).toString(),
          CarbsPerServing: passedvalue.carbohydratesPerServing
            .toFixed(3)
            .toString(),
          SugarsPerServing: passedvalue.sugarsPerServing.toFixed(1).toString(),
          ProteinPerServing: passedvalue.proteinPerServing
            .toFixed(3)
            .toString(),
          EnergyPer100: passedvalue.energyPer100.toFixed(1).toString(),
          FatPer100: passedvalue.totalFatPer100.toFixed(1).toString(),
          CarbsPer100: passedvalue.carbohydratesPer100.toFixed(1).toString(),
          SugarsPer100: passedvalue.sugarsPer100.toFixed(1).toString(),
          ProteinPer100: passedvalue.proteinPer100.toFixed(1).toString(),
          SodiumPerServing: passedvalue.sodiumPerServing.toFixed(1).toString(),
          SodiumPer100: passedvalue.sodiumPer100.toFixed(1).toString(),
        };
        if (passedvalue.totalFatPer100 < 0.1) fooddata.FatPer100 = "<1";
        if (passedvalue.totalFatPerServing < 0.1) fooddata.FatPerServing = "<1";

        if (passedvalue.mayContain.length > 0) {
          console.log("maycontain");
          customfoodData.Warning =
            "May Contain: " + passedvalue.mayContain.join(", ");

          fooddata.Warning =
            "May Contain: " + passedvalue.mayContain.join(", ");
        }
        console.log(passedvalue.contains);
        if (passedvalue.contains.length > 0) {
          customfoodData.Contains =
            "Contains: " + passedvalue.contains.join(", ");
        } else {
          customfoodData.Contains = "";
        }
        if (passedvalue.careInstructions.length > 0) {
          customfoodData.CareInstructions =
            "Care Instructions: " + passedvalue.careInstructions.join(",");

          fooddata.CareInstructions =
            "Care Instructions: " + passedvalue.careInstructions.join(",");
        }
        let options = {
          copies: copies, // Optional - Defaults: 1
          autoCut: true,
        };
        console.log(options);

        console.log(fooddata);
        let tag = new BrotherSdk({
          templatePath: LabelTemplate,
        });
        console.log("Template Path");
        console.log(tag.templatePath);
        if (passedvalue.isFood) {
          if (passedvalue.useCustomTemplate) {
            console.log(batchnumber);

            await tag.print(customfoodData, options);
            const printlog: printAuditViewModel = {
              batchNumber: customfoodData.BatchNumber,
              labels: copies,
              expiry: bestbefore,
              labelDetails: JSON.stringify(customfoodData),
              printedOn: moment(Date()).toISOString(true),
              printedBy: Getusername(),
              productId: passedvalue.id,
            };
            PrintAuditLog(printlog);
          } else {
            const printlog: printAuditViewModel = {
              batchNumber: fooddata.BatchNumber,
              expiry: bestbefore,
              labels: copies,
              labelDetails: JSON.stringify(fooddata),
              printedOn: moment(Date()).toISOString(true),
              printedBy: Getusername(),
              productId: passedvalue.id,
            };
            await tag.print(fooddata, options);
            PrintAuditLog(printlog);
          }
        } else {
          await tag.print(nonFoodData, options);
          const printlog: printAuditViewModel = {
            batchNumber: "n/a",
            expiry: "n/a",
            labelDetails: JSON.stringify(fooddata),
            printedOn: moment(Date()).toISOString(true),
            printedBy: Getusername(),
          };
          PrintAuditLog(printlog);
        }
      } catch (error) {
        alert(error);
        console.log({ error });
      }
    },
    [setWarningModalOpenState, setlabeltemplate]
  );

  React.useEffect(() => {
    GetConfig().then((response) => {
      if (response !== null && typeof response === "object")
        setConfiguration(response);
    });
  }, [setConfiguration]);
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    setSelectMode(true);
    setSelectedRow(params.row);

    props.onbestBeforeDateChangedValue(params.row.bestBefore);
    props.onBatchNumberChangedValue(params.row.batchNumber);
    props.onNumberofLabelsChangedValue(params.row.numberOfLabels);
  };

  const columns: GridColDef[] = [
    {
      field: "printedOn",
      headerName: "Printed ",
      minWidth: 200,
      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "printedBy",
      headerName: "Printed By",
      minWidth: 200,
      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "numberOfLabels",
      headerName: "Number of Labels",

      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "batchNumber",
      headerName: "Batch Number",
      minWidth: 200,
      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "bestBefore",
      headerName: "Best Before",
      minWidth: 130,
      headerClassName: "app-theme--header",
      resizable: true,
    },
  ];
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={OpenPrintLog}
        onClose={() => setOpenPrintLog(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={modalPrintLogStyle}>
          <Button
            disabled={props.printLogs?.length == 0 || props.printLogs == null}
            variant="outlined"
            onClick={() => {
              setOpenPrintLog(false);
              setSelectMode(false);
            }}
            startIcon={<BackIcon />}
          ></Button>
          <Typography>
            <h1>Print History</h1>
          </Typography>
          <Grid container columns={2} spacing={0.5} sx={{ m: 0, padding: 1 }}>
            <Grid item xs={1}>
              <DataGrid
                sx={{
                  "& .app-theme--header": {
                    backgroundColor: "primary.dark",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                    color: "white",
                    backgroundColor: "primary.dark",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important",
                  },
                }}
                onRowClick={handleRowClick}
                rows={props.printLogs}
                columns={columns}
                autosizeOnMount
                autosizeOptions={{
                  expand: true,
                  includeOutliers: true,
                  includeHeaders: true,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Container contentEditable={selectMode}>
                <Stack spacing={2}>
                  <TextField
                    size="small"
                    id="labels"
                    label="Number of Labels"
                    type="number"
                    value={props?.NumberOfLabels}
                    defaultValue={props?.NumberOfLabels}
                    onChange={(e) => {
                      parseInt(
                        props.onNumberofLabelsChangedValue(e.target.value)
                      );
                    }}
                    required
                  ></TextField>
                  <TextField
                    size="small"
                    id="BatchNumber"
                    label="Batch Number"
                    type="string"
                    value={props?.batchNumber}
                    defaultValue={props?.batchNumber}
                    onChange={(e) => {
                      props.onBatchNumberChangedValue(e.target.value);
                    }}
                    required
                  ></TextField>
                  <TextField
                    size="small"
                    id="BestBefore"
                    label="Best Before"
                    type="string"
                    value={props?.bestBeforeDate}
                    onChange={(e) => {
                      props.onbestBeforeDateChangedValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">mm/yy</InputAdornment>
                      ),
                    }}
                    required
                  ></TextField>
                  <Button
                    variant="outlined"
                    disabled={!selectMode}
                    onClick={() => {
                      printLabels(
                        props.product,
                        props.NumberOfLabels,
                        props.batchNumber,
                        props.bestBeforeDate,
                        labelconfiguration,
                        props.product.packingSize / props.product.servingSize
                      );
                    }}
                    startIcon={<PrintIcon />}
                  >
                    Print
                  </Button>
                </Stack>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWaringModal}
        onClose={() => setWarningModalOpenState(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Information missing
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please specify Batch Number and best before date
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setWarningModalOpenState(false);
            }}
            startIcon={<CheckIcon />}
          >
            ok
          </Button>
        </Box>
      </Modal>

      <Stack padding={1} spacing={2}>
        {props.product?.isFood && (
          <TextField
            size="small"
            id="BatchNumber"
            label="Batch Number"
            type="string"
            value={props.batchNumber}
            onChange={(e) => {
              props.onBatchNumberChangedValue(e.target.value);
            }}
            required
          ></TextField>
        )}
        {props.product?.isFood && (
          <TextField
            size="small"
            id="BestBefore"
            label="Best Before"
            type="string"
            value={props.bestBeforeDate}
            onChange={(e) => {
              props.onbestBeforeDateChangedValue(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">mm/yy</InputAdornment>
              ),
            }}
            required
          ></TextField>
        )}

        <TextField
          size="small"
          fullWidth
          id="labels"
          label="Number of Labels"
          type="number"
          value={props.NumberOfLabels}
          onChange={(e) => {
            parseInt(props.onNumberofLabelsChangedValue(e.target.value));
          }}
        ></TextField>

        {props.product?.id && (
          <Button
            variant="outlined"
            onClick={() => {
              printLabels(
                props.product,
                props.NumberOfLabels,
                props.batchNumber,
                props.bestBeforeDate,
                labelconfiguration,
                props.product.packingSize / props.product.servingSize
              );
            }}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        )}
        {props.product?.id && isAdmin && (
          <Button
            disabled={props.printLogs?.length == 0 || props.printLogs == null}
            variant="outlined"
            onClick={() => {
              setOpenPrintLog(true);
            }}
            startIcon={<HistoryIcon />}
          ></Button>
        )}
      </Stack>
    </>
  );
};

export default PrintDialog;
